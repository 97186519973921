import React from "react";

function PrivacyPolicy() {
  return (
    <div className="container legal">
      <h1>Privacy Policy</h1>
      <div className="legal-section">
        <h2>1. Introduction</h2>
        <p>
          This Privacy Policy explains how the Weight Widget Android App ("we,"
          "us," or "our") collects, uses, and discloses your personal
          information when you use our app (the "App"). By using the App, you
          agree to the collection and use of your information in accordance with
          this Privacy Policy.
        </p>
      </div>
      <div className="legal-section">
        <h2>2. Information Collection and Use</h2>
        <p>
          When you use the App, we do not collect or store any personal
          information. The App accesses your Fitbit account data, including your
          weight, using the Fitbit API. We do not store, share, or sell this
          data. The App only displays the information on your device.
        </p>
      </div>
      <div className="legal-section">
        <h2>3. Fitbit Account</h2>
        <p>
          To use the App, you must have a valid Fitbit account. You authorize us
          to access your Fitbit account to retrieve your weight data for display
          within the App. We do not have access to your Fitbit account login
          credentials.
        </p>
      </div>
      <div className="legal-section">
        <h2>4. Security</h2>
        <p>
          We take the security of your personal information seriously. However,
          no method of electronic transmission or storage is completely secure,
          and we cannot guarantee the absolute security of your data. It is your
          responsibility to protect your Fitbit account login credentials.
        </p>
      </div>
      <div className="legal-section">
        <h2>5. Third-Party Services</h2>
        <p>
          The App uses the Fitbit API to access your Fitbit account data. We are
          not responsible for the privacy practices of Fitbit or any other
          third-party services that you may use in connection with the App. We
          encourage you to review Fitbit's privacy policy to understand how they
          collect, use, and share your information.
        </p>
      </div>
      <div className="legal-section">
        <h2>6. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by updating the "Last Updated" date at the bottom
          of this document. Your continued use of the App after any changes to
          this Privacy Policy constitutes your acceptance of the new policy. If
          you do not agree with any changes, you must stop using the App and
          uninstall it from your device(s).
        </p>
      </div>
      <div className="legal-section">
        <h2>7. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at info@majorteknologies.com.
        </p>
      </div>
      <div className="legal-section">
        <h2>16. Revision Date</h2>
        <p>Last Updated: March 17, 2023</p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
