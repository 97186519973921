import React from "react";

function FAQ() {
  return (
    <div className="container legal">
      <h1>Frequently Asked Questions</h1>
      <div className="legal-section">
        <h2>What does the Weight Widget App do?</h2>
        <p>
          The Weight Widget App is designed to help users track their weight by
          displaying it on a widget on their Android device's home screen. The
          app integrates with the Fitbit API to fetch the user's weight
          information and update the widget automatically.
        </p>
      </div>
      <div className="legal-section">
        <h2>How do I connect the Weight Widget App to my Fitbit account?</h2>
        <p>
          To connect the Weight Widget App to your Fitbit account, follow these
          steps:
          <ol>
            <p></p>
            <li>Open the Weight Widget App on your Android device.</li>
            <li>
              The app will prompt you to authorize access to your Fitbit
              account.
            </li>
            <li>
              Log in to your Fitbit account and grant the necessary permissions.
            </li>
            <li>
              Once you have successfully authorized the app, it will start
              fetching your weight information from your Fitbit account and
              display it on the widget.
            </li>
          </ol>
        </p>
      </div>
      <div className="legal-section">
        <h2>How often does the Weight Widget App update my weight info?</h2>
        <p>
          The Weight Widget App updates your weight information automatically
          after you have successfully connected it to your Fitbit account. The
          widget refreshes and displays the latest weight data whenever you sign
          in or when the app updates the data from the Fitbit API.
        </p>
      </div>
      <div className="legal-section">
        <h2>Can I manually refresh my weight data in the Weight Widget App?</h2>
        <p>
          Yes, you can manually refresh your weight data in the Weight Widget
          App by following these steps:
          <p></p>
          <ol>
            <li>Open the Weight Widget App on your Android device.</li>
            <li>
              If you are already signed in, the app will automatically fetch the
              latest weight data from your Fitbit account.
            </li>
            <li>
              To manually refresh the data, tap the "Refresh" button in the app.
            </li>
          </ol>
        </p>
      </div>
      <div className="legal-section">
        <h2>
          How do I troubleshoot issues with the Weight Widget App not displaying
          my weight?
        </h2>
        <p>
          If the Weight Widget App is not displaying your weight, you can try
          the following steps to troubleshoot the issue:
          <p></p>
          <ol>
            <li>
              Ensure that the app is connected to your Fitbit account and has
              the necessary permissions to access your weight data.
            </li>
            <li>
              Check if your Fitbit device has synced your latest weight data
              with your Fitbit account. The Weight Widget App will only display
              the data that is available on your Fitbit account.
            </li>
            <li>
              Manually refresh the weight data in the Weight Widget App by
              tapping the "Refresh" button.
            </li>
            <li>
              If the issue persists, try logging out of the app and logging back
              in to reauthorize the app's access to your Fitbit account.
            </li>
          </ol>
        </p>
      </div>
    </div>
  );
}

export default FAQ;
