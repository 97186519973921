import React from "react";

function TermsOfService() {
  return (
    <div className="container legal">
      <h1>Terms of Service</h1>
      <div className="legal-section">
        <h2>1. Introduction</h2>
        <p>
          Welcome to Weight Widget Android App ("we," "us," or "our"). By
          installing and using Weight Widget Android App (the "App"), you agree
          to be bound by these Terms of Service ("Terms"). Please read these
          Terms carefully, and if you do not agree with any part of them, do not
          install or use the App.
        </p>
      </div>
      <div className="legal-section">
        <h2>2. Eligibility</h2>
        <p>
          You must be at least 13 years old to use the App. By using the App,
          you represent and warrant that you meet this age requirement.
        </p>
      </div>
      <div className="legal-section">
        <h2>3. License to Use the App</h2>
        <p>
          Subject to your compliance with these Terms, we grant you a limited,
          non-exclusive, non-transferable, non-sublicensable, revocable license
          to install and use the App on your personal device(s) for your
          personal use.
        </p>
      </div>
      <div className="legal-section">
        <h2>4. Account and Data</h2>
        <p>
          To use the App, you must have a valid Fitbit account. You are
          responsible for maintaining the confidentiality of your account
          information and for any activities that occur under your account. The
          App accesses your Fitbit account to retrieve your weight data. We do
          not store, share, or sell your data. The App only displays the
          information on your device.
        </p>
      </div>
      <div className="legal-section">
        <h2>5. Restrictions</h2>
        <p>
          You may not: a) Reverse-engineer, decompile, disassemble, or otherwise
          attempt to derive the source code of the App. b) Modify, adapt,
          translate, or create derivative works based on the App. c) Rent,
          lease, sublicense, sell, assign, or otherwise transfer your rights in
          the App. d) Use the App for any illegal or unauthorized purpose, or in
          any manner that violates applicable laws and regulations.
        </p>
      </div>
      <div className="legal-section">
        <h2>6. Intellectual Property</h2>
        <p>
          All intellectual property rights in the App, including but not limited
          to copyrights, trademarks, and trade secrets, are owned by us or our
          licensors. Your use of the App does not grant you any rights in or to
          our intellectual property, except for the limited license provided in
          these Terms.
        </p>
      </div>
      <div className="legal-section">
        <h2>7. Updates and Support</h2>
        <p>
          We may, but are not obligated to, provide updates, enhancements, or
          modifications to the App. We are not responsible for providing support
          or maintenance for the App. You acknowledge that we have no obligation
          to create or provide any updates, enhancements, or modifications to
          the App.
        </p>
      </div>
      <div className="legal-section">
        <h2>8. Termination</h2>
        <p>
          We may, at our sole discretion, terminate your license to use the App
          if you breach any of these Terms. Upon termination, you must cease all
          use of the App and uninstall it from your device(s).
        </p>
      </div>
      <div className="legal-section">
        <h2>9. Disclaimer of Warranties</h2>
        <p>
          The App is provided "as is" and "as available," without any
          warranties, express or implied, including but not limited to
          warranties of merchantability, fitness for a particular purpose, and
          non-infringement. We do not warrant that the App will be error-free,
          uninterrupted, or secure, or that it will meet your requirements or
          expectations.
        </p>
      </div>
      <div className="legal-section">
        <h2>10. Limitation of Liability</h2>
        <p>
          To the maximum extent permitted by law, we will not be liable for any
          direct, indirect, incidental, special, consequential, or punitive
          damages arising out of or related to your use of the App or these
          Terms, even if we have been advised of the possibility of such
          damages.
        </p>
      </div>
      <div className="legal-section">
        <h2>11. Governing Law and Jurisdiction</h2>
        <p>
          These Terms will be governed by and construed in accordance with the
          laws of the United States of America, without regard to its conflict
          of law provisions. You agree to submit to the exclusive jurisdiction
          of the courts located in [Your Jurisdiction] for any disputes arising
          out of or related to these Terms or your use of the App.
        </p>
      </div>
      <div className="legal-section">
        <h2>12. Changes to the Terms of Service</h2>
        <p>
          We reserve the right to modify or update these Terms at any time, at
          our sole discretion. When we make changes, we will notify you by
          updating the "Last Updated" date at the bottom of this document. Your
          continued use of the App after any such changes constitutes your
          acceptance of the new Terms. If you do not agree with any changes, you
          must stop using the App and uninstall it from your device(s).
        </p>
      </div>
      <div className="legal-section">
        <h2>13. Severability</h2>
        <p>
          If any provision of these Terms is found to be unenforceable or
          invalid by a court of competent jurisdiction, that provision will be
          limited or eliminated to the minimum extent necessary so that these
          Terms will otherwise remain in full force and effect and enforceable.
        </p>
      </div>
      <div className="legal-section">
        <h2>14. Waiver</h2>
        <p>
          Our failure to exercise or enforce any right or provision of these
          Terms shall not constitute a waiver of such right or provision.
        </p>
      </div>
      <div className="legal-section">
        <h2>15. Entire Agreement</h2>
        <p>
          These Terms, together with our Privacy Policy, constitute the entire
          agreement between you and us concerning the App and supersede any
          prior agreements, communications, representations, or understandings
          between you and us, whether oral or written, regarding the subject
          matter of these Terms.
        </p>
      </div>
      <div className="legal-section">
        <h2>16. Contact Information</h2>
        <p>
          If you have any questions about these Terms, please contact us at
          info@majorteknologies.com.
        </p>
      </div>
      <div className="legal-section">
        <h2>16. Revision Date</h2>
        <p>Last Updated: June 30, 2023</p>
      </div>
    </div>
  );
}

export default TermsOfService;
