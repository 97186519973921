import { useContext } from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";
import FAQ from "./FAQ";
import "./App.css";
import ThemeContext, { ThemeProvider } from "./ThemeContext";
import ThemeToggle from "./ThemeToggle";
import styled from "styled-components";
import mainLogo from "./main-logo.png";
import transparentLogo from "./transparentLogo.png";
import React from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-family: Arial, sans-serif;
`;

const Description = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: center;
  max-width: 800px;
  color: ${({ theme }) => theme.textColor};
  margin-bottom: 30px;
`;

const DownloadButton = styled.a`
  display: inline-block;
  background-color: ${({ theme }) => theme.buttonBackground};
  border: none;
  border-radius: 4px;
  color: ${({ theme }) => theme.buttonTextColor};
  font-size: 1.1rem;
  font-weight: 600;
  padding: 12px 24px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.buttonHoverBackground};
  }
`;

function App() {
  return (
    <ThemeProvider>
      <Router>
        <div className="App">
          <NavWithTheme />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/FAQ" element={<FAQ />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

function NavWithTheme() {
  const { theme } = useContext(ThemeContext);

  return (
    <nav className={theme}>
      <ul>
        <li>
          <img src={mainLogo} alt="Weight Widget Logo" className="logo" />
        </li>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/terms-of-service">Terms of Service</Link>
        </li>
        <li>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </li>
        <li>
          <Link to="https://majorteknologies.freshdesk.com/support/solutions">FAQ & Support </Link>
        </li>
        <li>
          <ThemeToggle />
        </li>
      </ul>
    </nav>
  );
}

function Home() {
  return (
    <Container>
      <img
        src={transparentLogo}
        alt="Transparent Weight Widget Logo"
        className="transparent-logo"
      />
      <p></p>
      <Description>
        Introducing the Weight Widget App - your personal weight tracking
        companion on your Android device. Seamlessly integrated with your Fitbit
        account, our innovative home screen widget keeps you motivated and
        informed about your weight goals right at your fingertips.
      </Description>
      <DownloadButton
        href="https://play.google.com/store/apps/details?id=com.example.weightwidget"
        className="google-play-badge-link"
      >
        <img
          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
          alt="Get it on Google Play"
          width="150"
        />
      </DownloadButton>
    </Container>
  );
}

export default App;
